import ComponentLoader from '../utils/ComponentLoader';
import { useInView } from 'react-intersection-observer';


const FlazioComponent = ( { item, zIndex } ) => {

    const type = item.t.charAt(0).toUpperCase() + item.t.slice(1);
    const DynamicComponent = ComponentLoader(type);

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
      });

      let className = '';
      let classAnimation = '';
      if(item.param && item.param.startAni){
        className = 'toAnimate';
        classAnimation = item.param.startAni;
      }

    return (
        <div ref={ref} id={item.id} className={inView? 'comp ' + classAnimation : 'comp '+ className} style={{ zIndex: zIndex, width: item.w + 'px', height: item.h + 'px', top: item.y + 'px', left: item.x + 'px' }}>
            <DynamicComponent item={item}  w={item.w} h={item.h} indfile={item.indfile} testo={item.c_testo} param={item.param} />
        </div>
    )
}

export default FlazioComponent;