import "./App.css";
import { useState, useEffect } from 'react';
import FlazioComponent from './components/FlazioComponent';
import {isMobile} from 'react-device-detect';
import './css/animations.css';

const allowedComponents = ["testo", "rettangolo", "cerchio", "immaginesingola","immagine", "orizzontalemenu", "pulsante"];

function App() {

  const domain = "www." + window.location.pathname.replace("/", "");
  let screenWidth = window.innerWidth;
  if(isMobile){
    let viewport_meta = document.getElementById('viewport-meta');
    viewport_meta.setAttribute('content','width=321');
    screenWidth = 321;
  }

  let counter = 1;
  let font_loaded = {};
  

  const [widthPagina, setWidthPagina] = useState(screenWidth);
  const zerx = -(widthPagina - 1000) / 2;
  const [heightPagina, setHeightPagina] = useState(600);

  const [fluid_components, setFluidComponent] = useState([]);
  const [page_components, setPageComponent] = useState([]);
  const [header_components, setHeaderComponent] = useState([]);
  const [footer_components, setFooterComponent] = useState([]);


  const mapComponent = (components) => {
    let mapped = [];
    let mapped_fluid = [];
    let maxHeight = 0;
    components.map((item, index) => {
      if (allowedComponents.indexOf(item.t) > -1) {
        if (item.h * 1 + item.y * 1 > maxHeight)
          maxHeight = item.h * 1 + item.y * 1;

        if (!item.param || !item.param.hide) {
          if (item.a === "L") {
            item.x = zerx + item.ax * 1;
            if (item.c === "W") {
              item.x = zerx;
              item.w = widthPagina;
            }
          }
          mapped.push(item);
        }
      }
    });

    if (maxHeight > heightPagina) {
      setHeightPagina(maxHeight);
    }

    return mapped;
  };


  const loadHeaderFooter = (page) => {
    let url = 'https://globaluserfiles.com/pages/' + domain + '/templates/' + page + '.xml?__ca=962&lang=it';
    if(isMobile)
      url += "&mobile=1";

    fetch(url).then(res => res.json())
      .then(page_data => {

        let mapped = mapComponent(page_data.componenti);

        if (page === "header")
          setHeaderComponent(mapped);
        else
          setFooterComponent(mapped);

        //setFluidComponent( old_mapped => [...old_mapped,mapped_fluid]);

      });
  };


  const loadPage = (page) => {
    let url = 'https://globaluserfiles.com/pages/' + domain + '/home.xml?__ca=863&lang=it';
    if(isMobile)
      url += "&mobile=1";

    fetch(url).then(res => res.json())
      .then(page_data => {
        //let {mapped, mapped_fluid} = mapComponent(page_data.componenti)
        //setPageComponent(mapped);
        setPageComponent(mapComponent(page_data.componenti));
        //setFluidComponent( old_mapped => [...old_mapped,mapped_fluid]);
      });
  };

  const loadDomainFontStyle = () => {
    fetch("https://" + domain + "/fontstyle.json")
      .then(res => res.json())
      .then(style_list => {
        let fonts = {};
        let custom_fonts = {};
        for (let style of style_list) {
          if (style.attr["font-family"] && style.attr["font-family"] != "") {
            if (style.attr["font-family"].indexOf("c-") > -1) {
              loadCustomFont(style.attr["font-family"]);
              custom_fonts[style.attr["font-family"]] = 1;
            }
            else
              fonts[style.attr["font-family"]] = 1;
          }
        }
        let str_font = 'https://fonts.googleapis.com/css?family=';
        for (let font in fonts)
          str_font += font + ':n,b,i,bi|';

        loadStyleSheet(str_font);
      });
  };

  async function loadCustomFont(name) {
    if (font_loaded[name])
      return;
    
    font_loaded[name] = 1;

    const myFont = new FontFace(name, 'url(https://' + domain + '/font/' + name + '.woff)');
    await myFont.load();
    document.fonts.add(myFont);
    
  }

  const loadStyleSheet = (url) => {
    var link = document.createElement("link");
    link.href = url;
    link.type = "text/css";
    link.rel = "stylesheet";
    document.getElementsByTagName("head")[0].appendChild(link);
  };


  useEffect(() => {
    loadHeaderFooter('header', header_components);
    loadHeaderFooter('footer', footer_components);
    loadPage("home");
    loadStyleSheet('https://' + domain + '/fontstyle.css');
    loadDomainFontStyle();

    window.addEventListener('resize', function () {
      //setWidthPagina(window.innerWidth);
    });

  }, []);

  return (
    <>
      <div id="website">
        <div id="aCC5">
          <div id="mc5" >
            <div className="header">
              {
                header_components.map((item, index) => (
                  <FlazioComponent item={item} key={item.id} zIndex={counter++} />
                ))
              }
            </div>
            <div className="content">
              {
                page_components.map((item, index) => (
                  <FlazioComponent item={item} key={item.id} zIndex={counter++} />
                ))
              }
            </div>
            <div className="footer" style={{ position: 'absolute', 'top': heightPagina + 'px' }}>
              {
                footer_components.map((item, index) => (
                  <FlazioComponent item={item} key={item.id} zIndex={counter++} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div width={widthPagina} className="fluid_components">
        {fluid_components}
      </div>
    </>
  );

}

export default App;
